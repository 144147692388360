import * as React from "react"

import Layout from "@components/layout"
import Seo from "@components/Seo.js"

const NotFoundPage = () => {
    return (
        <Layout>
            <Seo title="404: Not found" />
            <div className="error-page">
                <div className="error-page__info">
                    <p className="text-background">404</p>
                    <div className="error-page__wrapper">
                        <div className="error-page__text">
                            <h4>Something went wrong</h4>
                            <h1>Page not found</h1>
                        </div>
                    </div>
                </div>

                <h2 className="error-page__subtitle">
                    Navigate back to our site
                </h2>

                <div className="wrapper">
                    <section className="profile-home">
                        <a
                            href={process.env.SHOP_API +'/support/ticket/new'}
                            className="profile-home__link-container"
                        >
                            <div className="profile-home__card profile-home__card_help">
                                <span className="profile-home__card-number">&nbsp;</span>
                                <span className="profile-home__card-title">Get help</span>
                            </div>
                        </a>
                        <a href="/" className="profile-home__link-container">
                            <div className="profile-home__card profile-home__card_home">
                                <span className="profile-home__card-number">&nbsp;</span>
                                <span className="profile-home__card-title">Home page</span>
                            </div>
                        </a>
                        <a
                            href="/writing-services"
                            className="profile-home__link-container"
                        >
                            <div className="profile-home__card profile-home__card_services">
                                <span className="profile-home__card-number">&nbsp;</span>
                                <span className="profile-home__card-title">Services</span>
                            </div>
                        </a>
                        <a href="/support/faq" className="profile-home__link-container">
                            <div className="profile-home__card profile-home__card_faq">
                                <span className="profile-home__card-number">&nbsp;</span>
                                <span className="profile-home__card-title">F.A.Q.</span>
                            </div>
                        </a>
                    </section>
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage
